import React from "react";
import { graphql } from "gatsby";
import { ArticleProps, NavigatedToState } from "../types";
import Layout from "../components/layout";
import { makeStyles } from "@material-ui/core/styles";
// import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { formatDate } from "../utils/formatDate";
import SEO from "../components/seo";
import Comments from "../components/Comments";
// import GoBack from "../components/GoBack";
import { useGlobalStyles } from "../style";
import Like from "../components/Like";

const useStyles = makeStyles(theme => ({
  link: {
    margin: theme.spacing(0, 1, 0, 1)
  },
  text: {
    alignText: "justify"
  },
  divider: {
    margin: theme.spacing(1, 0, 1, 0)
  },
  img: { width: 200, float: "right", margin: theme.spacing(0, 0, 2, 2) },
  space: { height: theme.spacing(2) }
}));

export default function Template({
  data: { markdownRemark: article },
  location
}: ArticleProps & NavigatedToState) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  return (
    <Layout>
      <SEO
        title={article.frontmatter.title}
        description={article.excerpt}
        image={article.frontmatter.image.childImageSharp.fluid.src}
        large
        />
      {/*<GoBack navigatedTo={location?.state?.navigatedTo} />
      <Divider className={classes.divider} />*/}
      <div className="film-review">
        <Typography variant="h4" component="h1" style={{ marginBottom: 40, fontWeight: "bold" }}>
          {article.frontmatter.title}
        </Typography>
      </div>
      <Typography
        variant="body1"
        component="div"
        className={`${classes.text} ${globalClasses.article} article film-review`}
        dangerouslySetInnerHTML={{ __html: article.html }}
      />
      <div className={classes.space} />
      <div style={{ display: "flex" }}>
        <Typography variant="body2" style={{ marginRight: 20 }}>
          Posted by {article.frontmatter.author} on{" "}
          {formatDate(article.frontmatter.date)}
        </Typography>
        {typeof window !== "undefined" ? (
          <Like path={article.frontmatter.path} />
        ) : null}
      </div>



      
      {typeof window !== "undefined" ? (
        <Comments path={article.frontmatter.path} />
      ) : null}
    </Layout>
  );
}

export const postQuery = graphql`
  query ArticleByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt
      frontmatter {
        path
        title
        author
        date
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              src
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
